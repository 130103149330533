.rootcode-progruz {
  .scenario {
    &__back {
      display: inline-flex;
      align-items: center;
      font-weight: 400;
      font-size: 14px;
      margin-bottom: 30px;
    }

    &__arrow {
      margin-right: 10px;
    }
  }

  .description-scenario {
    margin-bottom: 48px;

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
      margin-bottom: 60px;

      @media (max-width: $tablet) {
        margin-bottom: 40px;
      }
    }

    &__title {
      color: #01091e;
      font-family: Manrope;
      font-size: 30px;
      font-weight: 600;
      line-height: 100%;
      letter-spacing: -0.9px;
    }

    &__buttons {
      display: flex;
      gap: 8px;
      flex-wrap: wrap;
    }

    &__button {
      &.small {
        padding: 12px;
      }
    }

    &__fields {
      display: flex;
      gap: 16px;
      flex-wrap: wrap;
    }

    &__field {
      display: flex;
      flex-direction: column;
      gap: 4px;
      flex-shrink: 0;

      textarea {
        min-height: 41px;
      }
    }

    &__label {
      padding-left: 12px;
      color: #89929a;
      font-family: Manrope;
      font-size: 14px;
      font-weight: 400;
      line-height: 100%;
      letter-spacing: -0.28px;
    }

    &__input {
      width: 100%;
      padding: 12px;
      border-radius: 8px;
      background-color: #f4f6f8;
      color: var(--black, #0f2850);
      font-family: Manrope;
      font-size: 15px;
      font-weight: 400;
      line-height: 115%;
      letter-spacing: -0.3px;
      border: none;

      &:focus {
        outline: 1px solid #89929a;
      }

      &:disabled {
        color: #89929a;
      }
    }

    .picker-description {
      &__field {
        position: relative;
      }

      &__input {
        height: 54px;
        background: $white;
        border-radius: 8px;
        border: none;
        padding: 12px;
      }
    }
  }

  .tabs-nav {
    display: inline-flex;
    background: $white;
    padding: 2px;
    border-radius: 8px;
    margin-bottom: 18px;
    color: #000;
    font-family: Manrope;
    font-size: 16px;
    font-weight: 400;
    line-height: 115%;
    /* 18.4px */
    letter-spacing: -0.32px;

    &__item {
      padding: 8px 16px;
      border-radius: 8px;
      cursor: pointer;
      transition: 0.2s;

      &.active {
        background: $blue;
        color: $white;
      }
    }
  }

  .tab-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .tab {
    background: #f9fafc;
    padding: 16px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .header-tab {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 24px;
    position: relative;
    z-index: 12;

    &__trigger {
      margin: 0;
      display: flex;
      align-items: center;
      gap: 8px;
      color: #000;
      font-family: Manrope;
      font-size: 16px;
      font-weight: 500;
      line-height: 100%;
      /* 16px */
      letter-spacing: -0.48px;
      cursor: pointer;

      svg {
        transform: rotate(-90deg);

        &.open {
          transform: rotate(0);
        }
      }
    }

    &__actions {
      display: flex;
      gap: 8px;
    }

    &__action {
      padding: 12px;
      border: 1px solid #e1e8ee;
      border-radius: 8px;
      background: $white;
      position: relative;
      transition: 0.2s;

      &:last-child svg {
        width: 24px;
        height: 24px;
        color: #95A4AF;
        transition: 0.2s;
      }

      &:not(:last-child) svg {
        width: 24px;
        height: 24px;
        color: $blue;
        transition: 0.2s;
      }

      &:hover {
        background-color: $darkBlue;

        .header-tab__notif {
          opacity: 1;
          visibility: visible;
        }

        svg {
          color: $white;
        }
      }
    }

    &__notif {
      position: absolute;
      right: 0;
      top: calc(100% + 12px);
      background: #fff;
      border-radius: 8px;
      box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.1);
      padding: 12px;
      color: #000;
      font-family: Manrope;
      font-size: 15px;
      font-weight: 400;
      line-height: 115%;
      /* 17.25px */
      letter-spacing: -0.3px;
      white-space: nowrap;
      opacity: 0;
      visibility: hidden;
      transition: 0.2s;

      &::before {
        content: '';
        position: absolute;
        bottom: 100%;
        right: 16px;
        border: 10px solid transparent;
        border-bottom: 10px solid white;
      }
    }

    &__filters {
      display: flex;
      width: 100%;
      gap: 16px;
      position: relative;
      z-index: 11;
    }

    &__filter {
      width: 100%;
    }
  }

  .resize-none {
    resize: none;
  }

  .table {
    max-height: 800px;
    overflow: auto;
    border-radius: 8px;
    margin: 0;
    border: 1px solid #edf1f4;

    &__wrapper {
      width: fit-content;
      min-width: 100%;
    }

    .header-table {
      display: flex;
      min-width: 100%;
      width: fit-content;
      color: $white;
      font-family: Manrope;
      font-size: 14px;
      font-weight: 400;
      line-height: 115%;
      letter-spacing: -0.28px;
      background: $turquoise;
      position: sticky;
      top: 0;
      z-index: 10;
      border-bottom: 1px solid $white;

      &__row {
        display: flex;
      }

      &__item {
        display: flex;
        align-items: center;
        height: auto;
        gap: 12px;
        border-right: 1px solid $white;
        padding: 0 12px;
        flex-shrink: 0;
        flex-grow: 1;
        position: relative;

        &:last-child {
          border: none;
        }

        &:first-child,
        &:nth-child(2),
        &:nth-child(3) {
          &:not(:last-child) {
            .table-header__filter {
              left: 0;
              right: auto;
            }
          }
        }
      }

      &__wrapper {
        border-right: 1px solid $white;
        flex-shrink: 0;
      }

      &__icons {
        display: flex;
        gap: 10px;
        position: relative;
      }

      &__icon {
        cursor: pointer;

        &.info {
          &:hover {
            & ~ .header-table__info {
              opacity: 1;
              visibility: visible;
              box-shadow: 0px 4px 8px 0px #03071214;

            }
          }
        }

        &.sort {
          &.rotate {
            transform: scaleY(-1);
          }
        }
      }

      &__label {
        border-bottom: 1px solid $white;
        position: relative;
      }

      &__text {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
      }

      &__info {
        max-width: 300px;
        width: max-content;
        font-size: 12px;
        padding: 12px;
        background: $white;
        color: $dark;
        border-radius: 8px;
        position: absolute;
        left: -15px;
        top: 190%;
        opacity: 0;
        visibility: hidden;
        transition: 0.2s linear;

        &::before {
          content: '';
          bottom: 100%;
          position: absolute;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-bottom: 8px solid $white;
        }

        &:nth-child(-n + 3) {
          right: 29px;
          left: auto;

          &::before {
            right: 15px;
            left: auto;
          }
        }
      }
    }

    &__body {
      background: $white;
    }

    .row__sticky {
      position: sticky;
      top: 81px;
      z-index: 9;
    }

    .row-table {
      display: flex;
      width: 100%;
      border-bottom: 1px solid #edf1f4;

      &:last-child {
        border-bottom: none;
      }

      &__wrapper {
        display: flex;
        border-right: 1px solid #edf1f4;
      }

      &__twrapper {
        display: flex;
        border-right: 1px solid #ffff;
      }

      &__bgwhite {
        background-color: #ffff;
      }

      &__item {
        display: flex;
        align-items: center;
        padding: 12px;
        min-height: 40px;
        height: auto;
        border-right: 1px solid #edf1f4;
        overflow: hidden;
        flex-shrink: 0;
        flex-grow: 1;

        &:last-child {
          border-right: none;
        }
      }

      &__total {
        display: flex;
        align-items: center;
        padding: 12px;
        min-height: 40px;
        height: auto;
        background-color: #EDF1F4;
        border-right: 1px solid #ffff;
        overflow: hidden;
        flex-shrink: 0;
        flex-grow: 1;

        &:last-child {
          border-right: none;
        }
      }

      &__item.changed {
        outline: 1px solid #5994c4;
      }

      &__input {
        height: 100%;
        width: 100%;
        text-align: right;
        border: none;
      }
    }

    .submodal_table-owners {
      position: absolute;
      top: 215px;
    }

    .modal-table {
      position: sticky;
      padding: 16px;
      border-radius: 8px;
      background: $white;

      &--fact {
        width: 294px;
      }

      &--owners {
        width: 390px;
      }

      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 32px;
        color: #01091e;
        font-family: Manrope;
        font-size: 24px;
        font-weight: 500;
        line-height: 100%;
        /* 24px */
        letter-spacing: -0.72px;
      }

      &__label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #89929a;
        font-family: Manrope;
        font-size: 14px;
        font-weight: 400;
        line-height: 100%;
        /* 14px */
        letter-spacing: -0.28px;
        margin-bottom: 8px;

        &-filter {
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;

            svg path {
                fill: #000;
            }
        }
      }

      &__input {
        border: 1px solid #d9e0e5;
        border-radius: 8px;
        padding: 8px;
        color: #000;
        font-family: Manrope;
        font-size: 16px;
        font-weight: 400;
        line-height: 120%;
        /* 19.2px */
        letter-spacing: -0.48px;
        background: $white;
        margin-bottom: 16px;
        width: 100%;
        transition: 0.2s;

        &:focus {
          border-color: $blue;
        }

        &:disabled {
          background: #f4f4f4;
          border-color: #d9e0e5;
        }
      }

      &__footer {
        display: flex;
        gap: 16px;
        margin-top: 14px;

        button {
          padding: 12px 24px;
          font-family: Manrope;
          font-size: 16px;
          line-height: 120%;
          /* 19.2px */
          letter-spacing: -0.48px;
          width: 100%;
        }
      }
    }
  }
}
