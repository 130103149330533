.rootcode-progruz {
  .scenarios {
    &__title {
      color: #01091e;
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
      /* 30px */
      letter-spacing: -0.9px;
      margin-bottom: 32px;

      @media (max-width: $tabletSmall) {
        margin-bottom: 40px;
      }
    }
  }

  .filter-scenarios {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 32px 0;
    flex-wrap: wrap;
    gap: 16px;

    .button {
      min-width: 140px;
    }

    &__sort {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    &__title {
      color: #0f2850;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
      /* 14px */
      letter-spacing: -0.28px;
    }
  }

  .select-scenarios {
    display: flex;
    background: $white;
    position: relative;
    // z-index: 20;
    background-color: $white;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    border: 1px solid #d9e0e5;
    border-radius: 8px;
    transition: 0.2s linear;

    &.select-error {
      border-color: rgb(244, 157, 157);

      .select-scenarios__options {
        border-color: rgb(244, 157, 157);
      }
    }

    &.opened {
      .select-scenarios {
        &__options {
          opacity: 1;
          visibility: visible;
          pointer-events: auto;
          transform: translateY(0);
        }

        &__arrow {
          transform: rotate(180deg);
        }
      }
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 200px;
    }

    &__label {
      font-size: 14px;
      font-family: Manrope;
      color: #89929a;
    }

    &__title {
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;
      color: $dark;
      font-family: Manrope;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      /* 16px */
      letter-spacing: -0.32px;
      flex-grow: 1;
      justify-content: space-between;
      max-width: 100%;
      padding: 8px;

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 85%;
      }
    }

    &__options {
      position: absolute;
      top: 100%;
      left: -1px;
      z-index: 11;
      width: calc(100% + 2px);
      max-height: 250px;
      overflow-y: auto;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      transform: translateY(-10px);
      background-color: $white;
      border-radius: 8px;
      border: 1px solid #d9e0e5;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
      padding: 4px;
      cursor: default;
      transition: 0.2s linear;

      // &::-webkit-scrollbar {
      //   width: 5px;
      // }

      // &::-webkit-scrollbar-track {
      //   background-color: $grey;
      // }

      // &::-webkit-scrollbar-thumb {
      //   border-radius: 4px;
      //   background-color: $gray;
      // }
    }

    &__option {
      width: 100%;
      text-align: left;
      color: $dark;
      font-family: Manrope;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      /* 12px */
      letter-spacing: -0.24px;
      padding: 12px;
      border-radius: 8px;

      @media (any-hover: hover) {
        &:hover {
          background: #edf1f4;
        }
      }
    }

    &__not-found {
      width: 100%;
      text-align: left;
      color: $dark;
      font-family: Manrope;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      /* 12px */
      letter-spacing: -0.24px;
      padding: 12px;
    }

    &__arrow {
      color: #95a4af;
      flex-shrink: 0;
      transition: 0.2s linear;
    }
  }

  .radio-scenarios {
    display: flex;
    align-items: center;
    font-family: Manrope;

    &__item {
      font-family: $fontFamilySecond;
      font-weight: 400;
      font-size: 18px;
      cursor: pointer;
      color: $grey;

      &.selected {
        font-weight: 600;
        color: $dark;
      }

      &:not(:first-child) {
        margin-left: 8px;
        padding-left: 8px;
        position: relative;

        &::before {
          content: '/';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translate(-50%, -50%);
          font-weight: 400;
        }
      }
    }
  }

  .scenarios__table {
    background-color: #edf1f4;
    width: 100%;
    overflow: auto;
    border-radius: 8px;
    max-height: 600px;

    // &::-webkit-scrollbar {
    //   width: 4px;
    // }

    // &::-webkit-scrollbar:horizontal {
    //   height: 4px;
    // }

    // &::-webkit-scrollbar-track {
    //   background-color: $grey;
    // }

    // &::-webkit-scrollbar-thumb {
    //   background-color: $black;
    //   border-radius: 4px;
    // }

    .scenarios-table {
      min-width: 1500px;
      width: fit-content;
      position: relative;
      font-family: $fontFamily;
      color: #000;
      background-color: $white;

      @media (max-width: 1600px) {
        width: fit-content;
      }

      &__header {
        display: flex;
        min-width: 100%;
        border-radius: 8px 8px 0 0;
        background-color: $turquoise;
        color: $white;
        position: sticky;
        top: 0;
        z-index: 5;
      }

      &__title {
        display: flex;
        align-items: center;
        min-height: 48px;
        height: auto;
        flex-shrink: 0;
        padding: 8px 12px 8px 15px;
        border-left: 1px solid #edf1f4;

        &:first-child {
          position: sticky;
          left: 0;
          z-index: 3;
          background-color: $turquoise;
          border-left: none;
        }
      }

      &__row {
        display: flex;
        min-width: 100%;
        width: fit-content;
        border-bottom: 1px solid #edf1f4;
        transition: 0.2s;

        @media (any-hover: hover) {
          &:hover {
            background-color: $gray;

            .scenarios-table__cell:first-child {
              background-color: $gray;
            }
          }
        }

        &:nth-last-child(-n + 4):not(:first-child):not(:nth-child(2)):not(:nth-child(3)) {
          .popup-scenarios-table {
            bottom: calc(100% + 15px);
            box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.1);
            top: auto;

            &::before {
              bottom: auto;
              top: 100%;
              border-bottom: 10px solid transparent;
              border-top: 10px solid $white;
            }
          }
        }
      }

      &__cell {
        display: flex;
        align-items: center;
        min-height: 40px;
        padding: 8px 12px 8px 15px;
        border-left: 1px solid #edf1f4;

        &:first-child {
          position: sticky;
          left: 0;
          z-index: 2;
          background-color: $white;
          border-left: none;
          background-color: $white;
          transition: 0.2s;
        }

        &:last-child {
          gap: 13px;
        }
      }

      &__button {
        position: relative;
        cursor: pointer;
      }
    }

    .popup-scenarios-table {
      position: absolute;
      top: calc(100% + 15px);
      right: -25px;
      opacity: 0;
      visibility: hidden;
      width: 246px;
      padding: 16px;
      z-index: 2;
      background: $white;
      cursor: default;
      box-shadow: 0px -4px 10px 4px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      transition: 0.2s;

      &::before {
        content: '';
        position: absolute;
        border: 10px solid transparent;
        border-bottom: 10px solid white;
        right: 20px;
        bottom: 100%;
      }

      &.open {
        opacity: 1;
        visibility: visible;
      }

      &__title {
        color: $dark;
        font-family: Manrope;
        font-size: 24px;
        font-weight: 500;
        line-height: 100%;
        /* 24px */
        letter-spacing: -0.72px;
        margin-bottom: 32px;
      }

      &__buttons {
        display: flex;
        justify-content: space-between;

        button {
          padding: 12px 16px;
        }
      }
    }
  }
}
